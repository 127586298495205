<template>
  <v-dialog v-model="isPickerOpen" persistent class="price-editor" width="980">
    <slot slot="activator"></slot>
    <v-card class="card">
      <v-icon @click="$emit('closeDialog')" class="close-icon">close</v-icon>
      <v-card-title v-if="editingPrice" class="price-editor__title"
        >Edit details</v-card-title
      >
      <v-card-title v-else class="price-editor__title"
        >Add a new price</v-card-title
      >

      <v-card-text>
        <section>
          <p class="warning-text">All items with an asterisk are required</p>
          <div v-if="loaded" class="price-row">
            <v-layout row>
              <v-flex mr-5 class="half">
                <label class="label" for="price-name">Price name</label>
                <v-text-field
                  id="price-name"
                  v-model="price.name"
                  solo
                  flat
                ></v-text-field>
              </v-flex>
              <v-flex class="half">
                <label class="label" for="active">Active</label>
                <v-select
                  id="active"
                  v-model="price.active"
                  item-text="name"
                  item-value="value"
                  :items="[
                    { name: 'Active', value: true },
                    { name: 'Disabled', value: false },
                  ]"
                  solo
                  flat
                  required
                />
              </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex mr-5 class="half">
                <entitlements-picker
                  class="entitlements-picker"
                  :readonly="editingPrice"
                  :activeEntitlements="convertEntitlements()"
                  @updated="updatedEntitlements($event)"
                />
              </v-flex>
              <v-flex class="half"> </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex mr-5 class="half">
                <label class="label" for="currency">Currency</label>
                <v-select
                  id="currency"
                  :readonly="editingPrice"
                  v-model="price.currency"
                  item-text="name"
                  item-value="value"
                  :items="supportedCurrencies"
                  solo
                  flat
                  required
                />
              </v-flex>
              <v-flex class="half">
                <label
                  v-if="price.currency === 'GBP'"
                  class="label"
                  for="amount"
                  >Amount as pence*</label
                >
                <label
                  v-else-if="price.currency === 'USD'"
                  class="label"
                  for="amount"
                  >Amount as cents*</label
                >
                <label v-else class="label" for="amount"
                  >Amount as minor units (e.g. pence, cents)*</label
                >
                <v-text-field
                  id="amount"
                  type="number"
                  :readonly="editingPrice"
                  v-model.number="price.amount"
                  solo
                  flat
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex mr-5 class="half">
                <label class="label" for="tax-behaviour">Tax Behaviour</label>
                <v-select
                  id="tax-behaviour"
                  :readonly="editingPrice"
                  v-model="price.taxBehavior"
                  item-text="name"
                  item-value="value"
                  :items="[
                    { name: 'Inclusive', value: 'inclusive' },
                    { name: 'Exclusive', value: 'exclusive' },
                  ]"
                  solo
                  flat
                  required
                />
              </v-flex>
              <v-flex class="half"> </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex mr-5 class="half">
                <label class="label" for="price-display"
                  >Button Price Display</label
                >
                <v-text-field
                  id="price-display"
                  v-model="price.priceDisplay"
                  solo
                  flat
                ></v-text-field>
              </v-flex>
              <v-flex class="half"> </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex mr-5 class="half">
                <label class="label" for="media-type">Package Type</label>
                <v-select
                  id="media-type"
                  :readonly="editingPrice"
                  v-model="price.type"
                  item-text="name"
                  item-value="value"
                  :items="[
                    { name: 'One Time', value: 'one-time' },
                    { name: 'Recurring', value: 'recurring' },
                  ]"
                  solo
                  flat
                  required
                />
              </v-flex>
              <v-flex class="half"> </v-flex>
            </v-layout>

            <v-layout row v-if="price.type === 'recurring'">
              <v-flex mr-5 class="half">
                <label class="label" for="currency">Interval</label>
                <v-select
                  id="currency"
                  :readonly="editingPrice"
                  v-model="price.recurring.interval"
                  item-text="name"
                  item-value="value"
                  :items="[
                    { name: 'Month', value: 'month' },
                    { name: 'Year', value: 'year' },
                    { name: 'Week', value: 'week' },
                    { name: 'Day', value: 'day' },
                  ]"
                  solo
                  flat
                  required
                />
              </v-flex>
              <v-flex class="half">
                <label class="label" for="interval-count">Interval Count</label>
                <v-text-field
                  id="interval-count"
                  type="number"
                  :readonly="editingPrice"
                  v-model.number="price.recurring.intervalCount"
                  solo
                  flat
                ></v-text-field>
              </v-flex>
            </v-layout>

            <template>
              <v-layout row>
                <v-flex mr-5 mb-4 class="half">
                  <label class="label" for="enable-country-restrictions"
                    >Enable country restrictions</label
                  >

                  <v-select
                    id="enable-country-restrictions"
                    readonly
                    v-model="enablePriceCountryRestrictions"
                    item-text="name"
                    item-value="value"
                    :items="[
                      { name: 'Yes', value: true },
                      { name: 'No', value: false },
                    ]"
                    solo
                    flat
                    required
                    @change="clearPriceCountryRestrictions()"
                  />
                </v-flex>
                <v-flex mt-4 class="half">
                  <p>
                    Country restrictions must be used for prices. This is where
                    you define which countries will see this price.
                  </p>
                </v-flex>
              </v-layout>

              <template v-if="enablePriceCountryRestrictions">
                <v-layout row>
                  <v-flex mr-5 class="half">
                    <label class="label" for="georestriction-profile"
                      >Georestriction Prepopulate</label
                    >
                    <v-select
                      v-if="georestrictionProfilesLoaded"
                      id="georestriction-profile"
                      v-model="price.accessData.countryProfile"
                      :items="georestrictionProfiles"
                      item-text="profileName"
                      item-value="id"
                      return-object
                      solo
                      flat
                      @change="selectPriceGeorestrictionProfile($event)"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex mt-4 class="half">
                    <p>
                      Use a predefined profile to quickly add a set of countries
                      for Georestriction. Any countries not in this list will be
                      prevented access.
                    </p>
                  </v-flex>
                </v-layout>

                <v-layout row>
                  <v-flex>
                    <label class="label" for="georestriction-countries-profile"
                      >Countries allowed</label
                    >

                    <v-autocomplete
                      v-if="allowedCountriesProfileLoaded"
                      id="georestriction-countries-profile"
                      v-model="price.accessData.allowedCountriesProfile"
                      :items="countriesList"
                      item-value="alpha2Code"
                      item-text="enShortName"
                      no-data-text="loading..."
                      multiple
                      solo
                      flat
                      dense
                      chips
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
              </template>
            </template>

            <v-layout row v-if="!modules.default.LFCExclusive">
              <v-flex mr-5 class="half">
                <label class="label" for="further-info">Further info</label>
                <v-textarea
                  solo
                  flat
                  rows="4"
                  id="further-info"
                  v-model="price.furtherInformation"
                ></v-textarea>
              </v-flex>
              <v-flex class="half"> </v-flex>
            </v-layout>
          </div>

          <p v-else>Loading...</p>
        </section>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="button" depressed @click="$emit('closeDialog')">
          Cancel
        </v-btn>

        <v-btn
          v-if="editingPrice"
          class="button"
          color="accent"
          depressed
          @click="savePrice()"
        >
          Save Price
        </v-btn>

        <v-btn
          v-else
          :disabled="!price.accessData.entitlements || !price.amount"
          class="button"
          color="accent"
          depressed
          @click="addPrice()"
        >
          Add Price
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import EntitlementsPicker from "@/components/Library/EntitlementsPicker";

export default {
  name: "PackagesPriceEditor",

  components: {
    EntitlementsPicker,
  },

  props: {
    editingPriceID: {
      type: String,
      required: false,
    },
    packageID: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    modules: null,
    loaded: false,
    editingPrice: null,
    isPickerOpen: true,
    supportedCurrencies: null,
    price: {
      id: "default",
      name: "",
      currency: "GBP",
      amount: null,
      taxBehavior: "inclusive",
      priceDisplay: "",
      type: "one-time",
      recurring: {},
      furtherInformation: "",
      accessData: {
        entitlements: "",
        allowedCountriesProfile: [],
        countryProfile: {},
      },
    },
    enablePriceCountryRestrictions: true,
    countriesList: [],
    countriesLoaded: false,
    georestrictionProfiles: [],
    georestrictionProfilesLoaded: false,
    allowedCountriesProfileLoaded: true,
  }),

  created() {
    this.modules = require("../../config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/modules.js");
    const defaultSettings = require("../../config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/defaultSettings.js");

    if (
      defaultSettings.default.supportedCurrencies &&
      defaultSettings.default.supportedCurrencies.length
    ) {
      this.supportedCurrencies = defaultSettings.default.supportedCurrencies;
    } else {
      this.supportedCurrencies = [
        { name: "GBP", value: "GBP" },
        { name: "USD", value: "USD" },
      ];
    }
  },

  mounted() {
    if (this.editingPriceID) {
      this.editingPrice = true;
      this.fetchPrice();
    } else {
      this.editingPrice = false;
      this.loaded = true;
    }

    this.getCountries();
    this.fetchGeorestrictionProfiles();
  },

  methods: {
    fetchPrice() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(
            `${process.env.VUE_APP_STREAMLINEAPI}/packages/${this.packageID}/price/${this.editingPriceID}`,
            { headers: { Authorization: jwt } }
          )
          .then((response) => {
            console.log("response", response);
            this.price = response.data.data.attributes;

            if (
              this.price.accessData.allowedCountriesProfile &&
              this.price.accessData.allowedCountriesProfile.length
            ) {
              this.enablePriceCountryRestrictions = true;
            }

            this.loaded = true;
          })
          .catch((e) => {
            console.error("Problem fetching a price", e);
          });
      });
    },

    addPrice() {
      const priceData = {
        name: this.price.name,
        active: this.price.active,
        accessData: {
          entitlements: this.price.accessData.entitlements,
          allowedCountriesProfile:
            this.price.accessData.allowedCountriesProfile,
          countryProfile: this.price.accessData.countryProfile,
        },
        currency: this.price.currency,
        amount: parseInt(this.price.amount),
        taxBehavior: this.price.taxBehavior,
        priceDisplay: this.price.priceDisplay,
        type: this.price.type,
        furtherInformation: this.price.furtherInformation,
      };

      if (this.price.type === "recurring") {
        priceData.recurring = this.price.recurring;
      }

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${process.env.VUE_APP_STREAMLINEAPI}/packages/${this.packageID}/price`,
          headers: { Authorization: jwt },
          data: {
            data: {
              type: "price",
              attributes: priceData,
            },
          },
        })
          .then((response) => {
            console.log("Price added: ", response);

            this.$emit("closeDialog");
          })
          .catch((e) => {
            console.error("Problem adding price: ", e);
          });
      });
    },

    savePrice() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "PATCH",
          url: `${process.env.VUE_APP_STREAMLINEAPI}/packages/${this.packageID}/price/${this.editingPriceID}`,
          headers: { Authorization: jwt },
          data: {
            data: {
              type: "price",
              id: this.editingPriceID,
              attributes: {
                name: this.price.name,
                active: this.price.active,
                priceDisplay: this.price.priceDisplay,
                furtherInformation: this.price.furtherInformation
                  ? this.price.furtherInformation
                  : "",
                accessData: {
                  entitlements: this.price.accessData.entitlements,
                  allowedCountriesProfile:
                    this.price.accessData.allowedCountriesProfile,
                  countryProfile: this.price.accessData.countryProfile,
                },
              },
            },
          },
        })
          .then((response) => {
            console.log("Price added: ", response);

            this.$emit("closeDialog");
          })
          .catch((e) => {
            console.error("Problem adding price: ", e);
          });
      });
    },

    convertEntitlements() {
      const entitlements = this.price.accessData.entitlements;
      if (entitlements) {
        return entitlements.split(",");
      }
    },

    updatedEntitlements(payload) {
      console.log("updatedEntitlements", payload);

      const filteredEntitlements = payload.filter(
        (element) => element !== undefined
      );

      this.price.accessData.entitlements = filteredEntitlements.toString();
    },

    selectPriceGeorestrictionProfile($event) {
      console.log("selectPriceGeorestrictionProfile", $event);

      this.allowedCountriesProfileLoaded = false;

      this.price.accessData.allowedCountriesProfile = $event.countries;

      this.allowedCountriesProfileLoaded = true;
    },

    fetchGeorestrictionProfiles() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_STREAMLINEAPI}/countryProfiles`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            console.log("Fetch profiles:", response);
            const profiles = response.data.data;
            this.georestrictionProfiles = profiles.map((item) => {
              return {
                id: item.id,
                profileName: item.attributes.profileName,
                countries: item.attributes.countries,
              };
            });

            this.georestrictionProfilesLoaded = true;
          })
          .catch((e) => {
            console.error("Problem fetching profiles", e);
            this.georestrictionProfilesLoaded = true;
          });
      });
    },

    getCountries() {
      this.countriesLoaded = false;
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_COUNTRIESAPI}/countries`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            this.countriesList = response.data;
            this.countriesLoaded = true;
          })
          .catch((e) => {
            console.error("Problem receiving countries", e);
          });
      });
    },

    clearPriceCountryRestrictions() {
      if (!this.enablePriceCountryRestrictions) {
        this.price.accessData.countryProfile = {};
        this.price.accessData.allowedCountriesProfile = [];
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 16px auto 0;
}

.half {
  width: 50%;
}

.hide {
  display: none;
}

.input-container {
  position: relative;
}

.input {
  min-height: 48px;
  min-width: 200px;
  border: 1px solid rgb(223, 227, 233);
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 4px 10px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input-text {
  max-width: calc(100% - 60px);
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
}

.input-date {
  background-color: peachpuff;
  padding: 2px 6px;
  border-radius: 6px;
  display: inline-block;
}

.input-ids {
  color: #7b6b80;
  opacity: 0.8;
}

.icon-container {
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
  border-radius: 50%;
  border: 2px solid grey;
  font-size: 0;
  cursor: pointer;
  padding: 8px;
}

.search-icon {
  font-size: 18px;
}

.card {
  position: relative;
  padding-bottom: 6px;
}

.close-icon {
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
  font-size: 16px;
}

.button {
  display: block;
  margin: 6px auto;
}

.price-editor {
  &__title {
    color: $purple;
    font-size: 21px;
    font-weight: 500;
  }
}

.entitlements-picker {
  /deep/ label:after {
    content: "*";
  }
}

.warning-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f6d2;
  padding: 8px 16px;
  border: 1px solid #d6d8b0;
  border-radius: 8px;
  margin-bottom: 16px;
}
</style>

