<template>
  <section class="page-content">
    <v-tabs v-model="active" v-if="dataLoaded">
      <v-tab :ripple="{ class: 'accent--text' }">Details</v-tab>
      <v-tab-item px-0 class="main-body">
        <v-alert
          :value="item.attributes.assetData.status === 'active'"
          color="error"
          icon="warning"
        >
          Live Streams can only be edited when inactive
        </v-alert>

        <template v-if="$route.params.id != 'create-stream'">
          <h3 class="subheading">Asset Info</h3>
          <section class="section-container">
            <table class="info-table">
              <tr>
                <td>Asset ID</td>
                <td>
                  <code>{{ item.id }}</code>
                </td>
              </tr>
              <tr>
                <td>Playback Policy</td>
                <td v-if="item.attributes.assetData.playbackIds">
                  {{ item.attributes.assetData.playbackIds[0].policy }}
                </td>
                <td v-else>No playback Policy</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{{ item.attributes.assetData.status }}</td>
              </tr>
              <tr>
                <td>Mux Asset ID</td>
                <td>{{ item.attributes.assetData.id }}</td>
              </tr>
              <tr>
                <td>Latency Mode</td>
                <td>{{ item.attributes.assetData.latencyMode }}</td>
              </tr>
              <tr>
                <td>Low Latency</td>
                <td>{{ item.attributes.assetData.lowLatency }}</td>
              </tr>
              <tr>
                <td>Reconnect Window</td>
                <td>{{ item.attributes.assetData.reconnectWindow }}</td>
              </tr>
              <tr>
                <td>Created At</td>
                <td>
                  {{
                    item.attributes.assetData.createdAt
                      | moment("kk:mm (Z) DD/MM/YY")
                  }}
                </td>
              </tr>
              <tr>
                <td>Updated At</td>
                <td>
                  {{
                    item.attributes.assetData.updatedAt
                      | moment("kk:mm (Z) DD/MM/YY")
                  }}
                </td>
              </tr>
            </table>

            <div class="preview-video">
              <VideoPlayer
                v-if="item.attributes.assetData.playbackIds"
                :clipping="false"
                :entitlements="item.attributes.accessData.entitlements"
                :title="item.attributes.title"
                :playbackID="item.attributes.assetData.playbackIds[0].id"
                :policy="item.attributes.assetData.playbackIds[0].policy"
                :storyboardToken="item.attributes.assetData.storyboardToken"
                :videoID="item.attributes.videoID"
                mediaType="streams"
              />

              <p v-else>Playback ID missing</p>
            </div>
          </section>

          <h3 class="subheading">Encoder Info</h3>
          <section class="section-container">
            <v-switch
              v-model="revealShareInfo"
              :label="`Reveal Encoder Details`"
            ></v-switch>

            <table v-if="revealShareInfo" class="info-table info-table--share">
              <tr>
                <td>RTMP URL</td>
                <td>
                  <p>rtmps://global-live.mux.com:443/app</p>
                  <p>rtmp://global-live.mux.com:443/app</p>
                </td>
              </tr>
              <tr>
                <td>Stream Key</td>
                <td>{{ item.attributes.assetData.streamKey }}</td>
              </tr>
              <tr>
                <td>Encoder settings</td>
                <td>
                  <p><b>Common</b></p>
                  <ul>
                    <li>Video CODEC - H.264 (Main Profile)</li>
                    <li>Audio CODEC - AAC</li>
                  </ul>
                  <p><b>Great - 1080p 30fps</b></p>
                  <ul>
                    <li>Bitrate - 5000 kbps</li>
                    <li>Keyframe Interval - 2 seconds</li>
                  </ul>
                  <p><b>Good - 720p 30fps</b></p>
                  <ul>
                    <li>Bitrate - 3500 kbps</li>
                    <li>Keyframe Interval - 2 seconds</li>
                  </ul>
                  <p><b>Works - 480p 30fps</b></p>
                  <ul>
                    <li>Bitrate - 1000 kbps</li>
                    <li>Keyframe Interval - 5 seconds</li>
                  </ul>
                </td>
              </tr>
            </table>
          </section>
        </template>

        <h3 class="subheading">Details</h3>
        <section class="section-container">
          <v-layout row>
            <v-flex mr-5 class="half">
              <label class="label" for="title">Name<sup>*</sup></label>
              <v-text-field
                id="title"
                v-model="item.attributes.title"
                solo
                flat
                :readonly="item.attributes.assetData.status === 'active'"
                @change="updateData()"
                :error="basicValidateCheck(item.attributes.title)"
              ></v-text-field>
            </v-flex>
            <v-flex mt-4 class="half">
              <p><i>*Required</i> - Name of the stream for internal use</p>
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex mr-5 class="half">
              <label class="label" for="description">Description</label>
              <v-textarea
                solo
                flat
                rows="4"
                id="description"
                v-model="item.attributes.description"
                :readonly="item.attributes.assetData.status === 'active'"
                @change="updateData()"
              ></v-textarea>
            </v-flex>
            <v-flex mt-4 class="half">
              <p>
                Internal description of stream. Use this for any business logic
                around what and when this stream endpoint should be used
              </p>
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex mr-4 class="half">
              <div>
                <label class="label" for="stream-type">Stream Type</label>
                <v-select
                  id="stream-type"
                  v-model="item.attributes.assetData.audioOnly"
                  :readonly="$route.params.id != 'create-stream'"
                  item-text="name"
                  item-value="value"
                  :items="[
                    { name: 'Video and Audio', value: false },
                    { name: 'Audio only', value: true },
                  ]"
                  solo
                  flat
                  required
                  @change="status = 'needsSaving'"
                />
              </div>
            </v-flex>

            <v-flex mt-4 class="half">
              <p>
                Used to optimise the stream data for audio if this endpoint is
                only used for audio
              </p>
              <p><i>Please note that this cannot be changed once set</i></p>
            </v-flex>
          </v-layout>
        </section>

        <h3 class="subheading">Images</h3>
        <section class="section-container">
          <v-layout row>
            <v-flex>
              <ImageUploader
                :title="'Poster Image'"
                :widthOptionEnabled="false"
                :mediaLibraryID="item.attributes.imageData.posterImageID"
                @update="updatePosterImage($event)"
                :desc="'<p>Recommended size: 16:9</p><p>Used as a fallback poster image if a live video is not automatically playing or an image cannot be retrieved</p>'"
              />
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex>
              <ImageUploader
                :title="'Offline Image'"
                :widthOptionEnabled="false"
                :mediaLibraryID="item.attributes.imageData.offlineImageID"
                @update="updateOfflineImage($event)"
                :desc="'<p>Recommended size: 16:9</p><p>Used when the endpoint is idle and offline</p>'"
              />
            </v-flex>
          </v-layout>
        </section>

        <h3 class="subheading">Restrictions</h3>

        <section class="section-container">
          <v-layout row>
            <v-flex mr-5 mb-4 class="half">
              <label class="label" for="policy">Policy</label>

              <v-select
                v-if="item.attributes.assetData.playbackPolicy"
                id="policy"
                readonly
                v-model="item.attributes.assetData.playbackPolicy"
                item-text="name"
                item-value="value"
                :items="[
                  { name: 'Signed', value: 'signed' },
                  { name: 'Public', value: 'public' },
                ]"
                solo
                flat
                required
                @change="status = 'needsSaving'"
              />

              <v-select
                v-else-if="item.attributes.assetData.playbackIds"
                readonly
                id="policy"
                v-model="item.attributes.assetData.playbackIds[0].policy"
                item-text="name"
                item-value="value"
                :items="[
                  { name: 'Signed', value: 'signed' },
                  { name: 'Public', value: 'public' },
                ]"
                solo
                flat
                required
                @change="status = 'needsSaving'"
              />
            </v-flex>
            <v-flex mt-4 class="half">
              <p>
                A signed policy will secure the stream via a signed url. This is
                more secure than a public asset
              </p>

              <p>
                <i
                  >Please note, we've disabled the ability to change this to
                  public at present</i
                >
              </p>
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex mr-5 mb-4 class="half">
              <label class="label" for="policy">Asset Creation Policy</label>

              <v-select
                v-if="
                  item.attributes.assetData.newAssetSettings.playbackPolicies
                "
                readonly
                id="policy"
                v-model="
                  item.attributes.assetData.newAssetSettings.playbackPolicies[0]
                "
                item-text="name"
                item-value="value"
                :items="[
                  { name: 'Signed', value: 'signed' },
                  { name: 'Public', value: 'public' },
                ]"
                solo
                flat
                required
                @change="status = 'needsSaving'"
              />

              <v-select
                v-else-if="item.attributes.assetData.newAssetSettings"
                readonly
                id="policy"
                v-model="
                  item.attributes.assetData.newAssetSettings.playbackPolicy
                "
                item-text="name"
                item-value="value"
                :items="[
                  { name: 'Signed', value: 'signed' },
                  { name: 'Public', value: 'public' },
                ]"
                solo
                flat
                required
                @change="status = 'needsSaving'"
              />
            </v-flex>
            <v-flex mt-4 class="half">
              <p>
                A signed policy will secure the asset when it's converted from a
                stream to an ondemand asset via a signed url. This is more
                secure than a public asset
              </p>

              <p>
                <i
                  >Please note, we've disabled the ability to change this to
                  public at present</i
                >
              </p>
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex mr-5 mb-4 class="half">
              <entitlements-picker
                :activeEntitlements="item.attributes.accessData.entitlements"
                @updated="updatedEntitlements($event)"
              />
            </v-flex>
            <v-flex mt-4 class="half">
              <p>
                Select or enter the entitlements you want to restrict this asset
                to
              </p>
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex mr-5 mb-4 class="half">
              <label class="label" for="enable-country-restrictions"
                >Enable country restrictions</label
              >

              <v-select
                id="enable-country-restrictions"
                v-model="enableCountryRestrictions"
                item-text="name"
                item-value="value"
                :items="[
                  { name: 'Yes', value: true },
                  { name: 'No', value: false },
                ]"
                solo
                flat
                required
                :readonly="item.attributes.assetData.status === 'active'"
                @change="clearCountryRestrictions()"
              />
            </v-flex>
            <v-flex mt-4 class="half">
              <p>
                If country restrictions are disabled then users from all
                countries will be able to access this resource
              </p>
            </v-flex>
          </v-layout>

          <template v-if="enableCountryRestrictions">
            <v-layout row>
              <v-flex mr-5 class="half">
                <label class="label" for="georestriction-profile"
                  >Georestriction Prepopulate</label
                >
                <v-select
                  v-if="georestrictionProfilesLoaded"
                  id="georestriction-profile"
                  v-model="item.attributes.accessData.countryProfile"
                  :items="georestrictionProfiles"
                  item-text="profileName"
                  item-value="id"
                  return-object
                  solo
                  flat
                  :readonly="item.attributes.assetData.status === 'active'"
                  @change="
                    selectGeorestrictionProfile($event);
                    status = 'needsSaving';
                  "
                >
                </v-select>
              </v-flex>
              <v-flex mt-4 class="half">
                <p>
                  Use a predefined profile to quickly add a set of countries for
                  Georestriction. Any countries not in this list will be
                  prevented access.
                </p>
              </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex>
                <label class="label" for="georestriction-countries-profile"
                  >Countries allowed</label
                >

                <v-autocomplete
                  v-if="allowedCountriesProfileLoaded"
                  id="georestriction-countries-profile"
                  outline
                  v-model="item.attributes.accessData.allowedCountriesProfile"
                  :items="countriesList"
                  item-value="alpha2Code"
                  item-text="enShortName"
                  no-data-text="loading..."
                  multiple
                  solo
                  flat
                  dense
                  chips
                  @change="status = 'needsSaving'"
                >
                </v-autocomplete>
              </v-flex>
            </v-layout>

            <div
              v-if="item.attributes.accessData.allowedCountriesSpecific.length"
            >
              <p>
                These countries should be removed from the data, please notify
                Urban Zoo with this asset ID
              </p>
              <pre>{{
                item.attributes.accessData.allowedCountriesSpecific
              }}</pre>
            </div>

            <!-- <v-layout row>
              // Removed due to allowing editing on main list
              <v-flex>
                <label class="label" for="georestriction-countries-specific">Manually allowed countries</label>

                <v-autocomplete
                  id="georestriction-countries-specific"
                  outline
                  v-model="item.attributes.accessData.allowedCountriesSpecific"
                  :items="countriesList"
                  item-value="alpha2Code"
                  item-text="enShortName"
                  no-data-text="loading..."
                  multiple
                  solo
                  flat
                  clearable
                  dense
                  chips
                  :readonly="item.attributes.assetData.status === 'active'"
                  @change="status = 'needsSaving'"
                  >
                </v-autocomplete>
              </v-flex>
            </v-layout> -->
          </template>
        </section>

        <template v-if="$route.params.id != 'create-stream'">
          <h3 class="subheading">Tools</h3>

          <section class="section-container">
            <v-layout row v-if="statusLoaded">
              <v-flex mb-3 ml-1>
                <div
                  v-if="item.attributes.assetData.status === 'idle'"
                  class="status status--idle"
                >
                  Idle
                </div>
                <div
                  v-else-if="item.attributes.assetData.status === 'disabled'"
                  class="status status--disabled"
                >
                  Disabled
                </div>
                <div
                  v-else-if="item.attributes.assetData.status === 'active'"
                  class="status status--active"
                >
                  Active
                </div>
                <div v-else class="status">
                  {{ item.attributes.assetData.status }}
                </div>
              </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex mr-5 class="half">
                <template v-if="statusLoaded">
                  <v-btn
                    v-if="item.attributes.assetData.status === 'disabled'"
                    @click="enableStream()"
                    class=""
                    color="accent"
                    type="button"
                    outline
                    flat
                  >
                    <v-icon class="mr-2 mb-1" small>live_tv</v-icon>
                    Enable live stream
                  </v-btn>

                  <v-btn
                    v-else
                    @click="disableStream()"
                    class=""
                    color="accent"
                    type="button"
                    outline
                    flat
                  >
                    <v-icon class="mr-2 mb-1" small>tv_off</v-icon>
                    Disable live stream
                  </v-btn>
                </template>

                <div v-else class="inline-loader">
                  <inline-loader />
                </div>
              </v-flex>
              <v-flex mt-4 class="half">
                <p>
                  By disabling a stream you can prevent streams being
                  unintentionally pushed live, or disable one currently
                  broadcasting
                </p>

                <p>
                  <i
                    >Please note - the stream endpoint must be enabled to
                    receive and display a stream</i
                  >
                </p>
              </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex mt-3 mr-5 class="half">
                <v-btn
                  @click="resetStreamKey()"
                  class=""
                  color="warning"
                  type="button"
                  outline
                  flat
                >
                  <v-icon class="mr-2 mb-1" small>reset_tv</v-icon>
                  Reset Stream Key
                </v-btn>
              </v-flex>

              <v-flex mt-4 class="half">
                <p>
                  <i
                    >Warning: Only use this if you feel your Stream Key has been
                    comprimised. Please remember to share the updated Stream Key
                    with relevant parties</i
                  >
                </p>
                <p>Resetting the key will deactivate this stream</p>
              </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex mt-3 mr-5 class="half">
                <v-btn
                  @click="deleteStream()"
                  class=""
                  color="error"
                  type="button"
                  depressed
                >
                  <v-icon class="mr-2 mb-1" small>delete_forever</v-icon>
                  Delete Stream
                </v-btn>
              </v-flex>

              <v-flex mt-4 class="half">
                <p>
                  <i
                    >Warning: This will permanently remove this stream
                    endpoint</i
                  >
                </p>
              </v-flex>
            </v-layout>
          </section>
        </template>
      </v-tab-item>

      <!-- <v-tab :ripple="{ class: 'accent--text' }">History</v-tab>
      <v-tab-item px-0 class="main-body">

        <h3 class="subheading">Recent Assets</h3>

        <section class="section-container">
          <ol v-for="asset in item.attributes.assetData.recentAssetIds" :key="asset" class="">
            <li>
              <v-btn :to="`/streamline/ondemand/${asset}`">{{ asset }}</v-btn>
            </li>
          </ol>
        </section>
      </v-tab-item> -->

      <!-- <v-tab :ripple="{ class: 'accent--text' }">Analytics</v-tab>
      <v-tab-item px-0 class="main-body">
        <div class="triple-stats">
          <single-stat class="triple-stats__item" :title="`Views`" :value="1004" />

          <single-stat class="triple-stats__item" :title="`Unique Viewers`" :value="868" />

          <single-stat class="triple-stats__item" :title="`Playing Time`" :value="`1.1 hrs`" />
        </div>

        <a :href="`https://dashboard.mux.com/organizations/m3k6fq/environments/u5620k/video/live-streams/${item.attributes.assetData.id}/health`" target="_blank">In depth analytics</a>
      </v-tab-item> -->
    </v-tabs>

    <StickyFooter
      :errorMessage="errorMessage"
      :status="status"
      @buttonClicked="saveItem()"
    />
  </section>
</template>

<script>
import axios from "axios";
import EntitlementsPicker from "@/components/Library/EntitlementsPicker";
import ImageUploader from "@/components/Library/ImageUploader";
import InlineLoader from "@/components/Library/InlineLoader";
import StickyFooter from "@/components/Library/StickyFooter";
import VideoPlayer from "@/components/Streamline/VideoPlayer";
import SingleStat from "@/components/Library/StatBlocks/SingleStat";
import SquadTagger from "@/components/Library/Tagging/SquadTagger";
import SeasonTagger from "@/components/Library/Tagging/SeasonTagger";
import FixtureTagger from "@/components/Library/Tagging/FixtureTagger";

export default {
  name: "LiveStreamingContent",

  components: {
    EntitlementsPicker,
    ImageUploader,
    InlineLoader,
    StickyFooter,
    VideoPlayer,
    SingleStat,
    SquadTagger,
    SeasonTagger,
    FixtureTagger,
  },

  data: () => ({
    status: "saved",
    errorMessage: "",
    active: null,
    dataLoaded: false,
    statusLoaded: false,
    revealShareInfo: false,
    item: {
      attributes: {
        assetData: {
          audioOnly: false,
          playbackPolicy: "signed",
          newAssetSettings: {
            playbackPolicy: "signed",
          },
          // latencyMode: '',
          // maxContinuousDuration: '',
          // passthrough: '',
          // reconnectWindow: 60
        },
        assetCreationData: {},
        accessData: {
          entitlements: [],
          allowedCountriesSpecific: [],
          countryProfile: "",
          allowedCountriesProfile: [],
        },
        publicationData: {
          published: 1,
        },
        imageData: {
          posterImageID: "",
          posterImageKey: "",
          offlineImageID: "",
          offlineImageKey: "",
        },
      },
    },
    enableCountryRestrictions: false,
    countriesList: [],
    countriesLoaded: false,
    georestrictionProfiles: [],
    georestrictionProfilesLoaded: false,
    allowedCountriesProfileLoaded: true,
    validating: false,
  }),

  mounted() {
    if (this.$route.params.id != "create-stream") {
      this.fetchItem();
    } else {
      this.dataLoaded = true;
      this.status = "needsSaving";
    }

    this.getCountries();
    this.fetchGeorestrictionProfiles();
  },

  methods: {
    updatePosterImage(payload) {
      this.item.attributes.imageData.posterImageID = payload.mediaLibraryID;
      this.item.attributes.imageData.posterImageKey = payload.imageKey;

      this.updateData();
    },

    updateOfflineImage(payload) {
      this.item.attributes.imageData.offlineImageID = payload.mediaLibraryID;
      this.item.attributes.imageData.offlineImageKey = payload.imageKey;

      this.updateData();
    },

    updateData() {
      this.status = "needsSaving";
      this.errorMessage = "";
      console.log(this.item);
    },

    selectGeorestrictionProfile($event) {
      console.log("selectGeorestrictionProfile", $event);

      this.allowedCountriesProfileLoaded = false;

      this.item.attributes.accessData.allowedCountriesProfile =
        $event.countries;

      this.allowedCountriesProfileLoaded = true;
    },

    createStream() {
      this.$store.commit("startLoading");

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${process.env.VUE_APP_STREAMLINEAPI}/streams`,
          headers: { Authorization: jwt },
          data: {
            data: {
              type: "live-stream",
              attributes: {
                assetData: this.item.attributes.assetData,
                title: this.item.attributes.title,
                description: this.item.attributes.description,
                streamType: this.item.attributes.streamType,
                accessData: this.item.attributes.accessData,
                imageData: {
                  posterImageID: this.item.attributes.imageData.posterImageID,
                  posterImageKey: this.item.attributes.imageData.posterImageKey,
                  offlineImageID: this.item.attributes.imageData.offlineImageID,
                  offlineImageKey:
                    this.item.attributes.imageData.offlineImageKey,
                },
                // assetCreationData: this.item.attributes.assetCreationData
                publicationData: {
                  published: 1,
                },
              },
            },
          },
        })
          .then((response) => {
            console.log("Stream Created: ", response);

            this.$store.commit("completeLoading");
            this.status = "saved";

            this.$router.push({ path: "/streamline" });
          })
          .catch((e) => {
            console.error("Problem creating stream: ", e);
            this.errorMessage = "Problem creating stream";
            this.$store.commit("completeLoading");
          });
      });
    },

    fetchGeorestrictionProfiles() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_STREAMLINEAPI}/countryProfiles`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            console.log("Fetch profiles:", response);
            const profiles = response.data.data;
            this.georestrictionProfiles = profiles.map((item) => {
              return {
                id: item.id,
                profileName: item.attributes.profileName,
                countries: item.attributes.countries,
              };
            });

            this.georestrictionProfilesLoaded = true;
          })
          .catch((e) => {
            console.error("Problem fetching profiles", e);
            this.georestrictionProfilesLoaded = true;
          });
      });
    },

    fetchItem() {
      this.$store.commit("startLoading");

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_STREAMLINEAPI}/streams/${this.$route.params.id}`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            console.log("Fetch stream:", response);
            this.item = response.data.data;

            if (
              this.item.attributes.accessData.allowedCountriesProfile.length ||
              this.item.attributes.accessData.allowedCountriesSpecific.length
            ) {
              this.enableCountryRestrictions = true;
            }

            this.dataLoaded = true;
            this.$store.commit("completeLoading");
            this.statusLoaded = true;
            this.status = "saved";
          })
          .catch((e) => {
            console.error("Problem fetching stream", e);
            this.$store.commit("completeLoading");
            this.status = "needsSaving";
            this.errorMessage = "Problem fetching stream";
          });
      });
    },

    getStatus() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_STREAMLINEAPI}/streams/${this.$route.params.id}?fields=assetData.status`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            console.log("Fetch status:", response);
            // this.item = response.data.data
            this.item.attributes.assetData.status =
              response.data.data.attributes.assetData.status;
            this.statusLoaded = true;
          })
          .catch((e) => {
            console.error("Problem fetching status", e);
          });
      });
    },

    saveItem() {
      if (this.item.attributes.title) {
        if (this.$route.params.id === "create-stream") {
          this.createStream();
        } else {
          this.$store.commit("startLoading");

          this.$getCurrentUserJwToken().subscribe((jwt) => {
            axios({
              method: "PATCH",
              url: `${process.env.VUE_APP_STREAMLINEAPI}/streams/${this.$route.params.id}`,
              headers: { Authorization: jwt },
              data: {
                data: {
                  type: this.item.type,
                  id: this.item.id,
                  attributes: {
                    title: this.item.attributes.title,
                    description: this.item.attributes.description,
                    accessData: this.item.attributes.accessData,
                    assetData: {
                      latencyMode: "standard",
                    },
                    imageData: {
                      posterImageID:
                        this.item.attributes.imageData.posterImageID,
                      posterImageKey:
                        this.item.attributes.imageData.posterImageKey,
                      offlineImageID:
                        this.item.attributes.imageData.offlineImageID,
                      offlineImageKey:
                        this.item.attributes.imageData.offlineImageKey,
                    },
                    publicationData: {
                      published: 1,
                    },
                    // assetCreationData: this.item.attributes.assetCreationData
                  },
                },
              },
            })
              .then((response) => {
                console.log("Saved stream:", response);
                this.$store.commit("completeLoading");
                this.status = "saved";
              })
              .catch((e) => {
                console.error("Problem saving stream", e);

                if (
                  e.errors[0].detail ===
                  "Live Streams can only be changed when inactive"
                ) {
                  alert("Live Streams can only be edited when inactive");
                }
                this.$store.commit("completeLoading");
                this.status = "needsSaving";
                this.errorMessage = "Problem saving stream";
              });
          });
        }
      } else {
        alert("You must have a title set");
        this.errorMessage = "Validation error";
        this.validating = true;
        this.$store.commit("completeLoading");
      }
    },

    enableStream() {
      this.$store.commit("startLoading");
      this.statusLoaded = false;

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "PUT",
          url: `${process.env.VUE_APP_STREAMLINEAPI}/streams/${this.$route.params.id}/enable`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            console.log("Enabled stream:", response);
            this.$store.commit("completeLoading");
            this.status = "saved";

            setTimeout(() => {
              this.getStatus();
            }, 2000);
          })
          .catch((e) => {
            console.error("Problem enabling stream", e);
            this.$store.commit("completeLoading");
            this.status = "needsSaving";
          });
      });
    },

    disableStream() {
      this.$store.commit("startLoading");
      this.statusLoaded = false;

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "PUT",
          url: `${process.env.VUE_APP_STREAMLINEAPI}/streams/${this.$route.params.id}/disable`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            console.log("Disabled stream:", response);
            this.$store.commit("completeLoading");
            this.status = "saved";

            setTimeout(() => {
              this.getStatus();
            }, 2000);
          })
          .catch((e) => {
            console.error("Problem disabling stream", e);
            this.$store.commit("completeLoading");
            this.status = "needsSaving";
          });
      });
    },

    deleteStream() {
      if (
        confirm(
          "This will permanently delete the stream. \nAre you sure you want to continue?"
        )
      ) {
        this.$store.commit("startLoading");

        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "DELETE",
            url: `${process.env.VUE_APP_STREAMLINEAPI}/streams/${this.$route.params.id}`,
            headers: { Authorization: jwt },
          })
            .then((response) => {
              console.log("Delete stream:", response);
              this.$store.commit("completeLoading");

              this.$router.push("/streamline");
            })
            .catch((e) => {
              console.error("Problem deleting stream", e);

              alert("There was a problem deleting the stream");
              this.$store.commit("completeLoading");
            });
        });
      }
    },

    resetStreamKey() {
      if (
        confirm(
          "This will change the stream key. Any third parties will need to be provided the new one to stream to this endpoint. \nAre you sure you want to do this?"
        )
      ) {
        this.$store.commit("startLoading");

        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "POST",
            url: `${process.env.VUE_APP_STREAMLINEAPI}/streams/${this.$route.params.id}/reset`,
            headers: { Authorization: jwt },
          })
            .then((response) => {
              console.log("Reset stream key:", response);
              this.item = response.data.data;

              setTimeout(() => {
                this.getStatus();
              }, 2000);

              this.$store.commit("completeLoading");
              this.status = "saved";
            })
            .catch((e) => {
              console.error("Problem resetting stream key", e);
              this.$store.commit("completeLoading");
              this.status = "needsSaving";
            });
        });
      }
    },

    getCountries() {
      this.countriesLoaded = false;
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_COUNTRIESAPI}/countries`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            this.countriesList = response.data;
            this.countriesLoaded = true;
          })
          .catch((e) => {
            console.error("Problem receiving countries", e);
          });
      });
    },

    basicValidateCheck(value) {
      if (this.validating && !value) {
        return true;
      }
    },

    clearCountryRestrictions() {
      if (!this.enableCountryRestrictions) {
        this.item.attributes.accessData.countryProfile = {};
        this.item.attributes.accessData.allowedCountriesProfile = [];
        this.item.attributes.accessData.allowedCountriesSpecific = [];

        this.updateData();
      }
    },

    updatedEntitlements(payload) {
      // console.warn('updatedEntitlements', payload)

      const filteredEntitlements = payload.filter(
        (element) => element !== undefined
      );

      this.item.attributes.accessData.entitlements = filteredEntitlements;
      // console.warn('this.item.attributes.accessData.entitlements', this.item.attributes.accessData.entitlements)
      this.updateData();
    },
  },
};
</script>

<style scoped lang="scss">
.half {
  width: 50%;
}

.third-x2 {
  width: 66.66%;
}

.third {
  width: 33.33%;
}

.section-divider {
  margin-bottom: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid #dfe3e9;
}

.image {
  width: 100%;
  max-width: 300px;
}

.triple-stats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__item {
    width: calc(33.33% - 16px);
  }
}

.info-table {
  width: 100%;
  border: 1px solid #dfe3e9;
  border-radius: 4px;

  &--share {
    margin-top: 32px;
  }

  td {
    padding: 4px 8px;
    border-bottom: 1px solid #dfe3e9;

    &:first-child {
      padding-right: 20px;
      font-weight: 500;
      border-right: 1px solid #dfe3e9;
    }

    p {
      margin: 0 0 8px;
    }

    ul {
      list-style: disc;
      margin: 8px 0 16px;
    }
  }

  tr:last-child {
    td {
      border-bottom: 0;
    }
  }
}

.preview-video {
  margin: 16px 0 0 0;
  width: 50%;
}

.status {
  display: inline-block;
  padding: 3px 12px;
  margin: 0 8px 0 0;
  font-size: 10px;
  color: #fff;
  background-color: #ccc;
  border-radius: 4px;
  opacity: 1;

  &.status--idle {
    background-color: color(draftHighlight);
  }

  &.status--disabled {
    background-color: color(hiddenHighlight);
  }

  &.status--active {
    background-color: #77a64b;
  }
}

.inline-loader {
  height: 60px;
  width: 60px;
}

.preview-video {
  margin: 16px 0 0 0;
  width: 50%;
}
</style>
