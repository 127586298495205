<template>
  <section v-if="dataLoaded" class="main-body">
    <h3 class="subheading">Details</h3>
    <section class="section-container">
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="title">Title<sup>*</sup></label>
          <v-text-field
            id="title"
            v-model="item.attributes.title"
            solo
            flat
            @change="updateData()"
            :error="basicValidateCheck(item.attributes.title)"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Required</i> - Title of package</p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-4 class="half">
          <div>
            <label class="label" for="media-type">Package Type</label>
            <v-select
              id="media-type"
              v-model="item.attributes.packageType"
              item-text='name'
              item-value='value'
              :items="[
                { name: 'Match Pass', value: 'match-pass' },
                { name: 'Membership', value: 'membership' }
              ]"
              solo
              flat
              required
              @change="updateData()"/>
          </div>
        </v-flex>

        <v-flex mt-4 class="half">
          <p>The package type will enable this package to be shown in different areas and allow additional data based on type</p>
        </v-flex>
      </v-layout>

      <v-layout row v-show="item.attributes.priceData.length">
        <v-flex mr-5 class="half">
          <label class="label">Allowed Entitlements</label>
          <template v-for="price in item.attributes.priceData">
          <p v-if="price.active" :key="price.id">
            <span class="entitlement-price">{{price.name}} - </span>
            <span class="entitlement-pill" v-for="(entitlement, index) in price.accessData.entitlements.split(',')" :key="index">
              {{entitlement}}
            </span>
          </p>
          </template>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>The entitlements are added when creating and editing the price data.</p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="description">Description</label>
          <v-textarea
            solo
            flat
            rows="4"
            id="description"
            v-model="item.attributes.description"
            @change="updateData()"
          ></v-textarea>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>Short description detailing what this package is</p>
        </v-flex>
      </v-layout>

      <v-layout row v-if="item.attributes.packageType === 'match-pass'">
        <v-flex mr-5 class="half">
          <label class="label" for="match-id">Match ID</label>
          <v-text-field
            id="match-id"
            v-model="item.attributes.matchID"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>Reference to a specific match if required</p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="statement-descriptor">Statement Descriptor</label>
          <v-text-field
            solo
            flat
            id="statement-descriptor"
            v-model="item.attributes.statementDescriptor"
            maxlength="22"
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>An arbitrary string to be displayed on your customer's credit card or bank statement. While most banks display this information consistently, some may display it incorrectly or not at all.</p>

          <p>This may be up to 22 characters. The statement description may not include &lt;, &gt;, \, ", &#8217; characters, and will appear on your customer's statement in capital letters. Non-ASCII characters are automatically stripped. It must contain at least one letter.</p>

          <p><a href="https://stripe.com/docs/statement-descriptors" target="_blank">Find out more about Statement Descriptors</a></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="success-page">Successful payment page</label>
          <v-text-field
            solo
            flat
            id="success-page"
            placeholder="https://"
            v-model="item.attributes.successfulPaymentPage"
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>Redirect this package on payment success. <i>This is a full URL including https.</i> This will override the default setting in the web settings admin</p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="cancelled-page">Cancelled payment page</label>
          <v-text-field
            solo
            flat
            id="cancelled-page"
            placeholder="https://"
            v-model="item.attributes.cancelledPaymentPage"
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>Redirect this package on payment cancel. <i>This is a full URL including https.</i> This will override the default setting in the web settings admin</p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="order">Order</label>
          <v-text-field
            id="order"
            type="number"
            v-model.number="item.attributes.order"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>Reorder packages by applying a order number</p>
        </v-flex>
      </v-layout>
    </section>

    <h3 v-if="!modules.default.LFCExclusive" class="subheading">Styling</h3>
    <section v-if="!modules.default.LFCExclusive" class="section-container">
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="style-mode">Style mode</label>
          <v-select
            id="style-mode"
            v-model="item.attributes.styleData.styleMode"
            item-text='name'
            item-value='value'
            :items="[
              { name: 'Light mode', value: 'light' },
              { name: 'Dark mode', value: 'dark' }
            ]"
            solo
            flat
            required
            @change="updateData()"/>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>Select light or dark modes. You should also consider this when using the background image display type</p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="style-mode">Display type</label>
          <v-select
            id="style-mode"
            v-model="item.attributes.styleData.displayType"
            item-text='name'
            item-value='value'
            :items="[
              { name: 'Icon only', value: 'icon-only' },
              { name: 'Background image', value: 'background-image' },
              { name: 'Feature image', value: 'feature-image' },
            ]"
            solo
            flat
            required
            @change="updateData()"/>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>Visual display types for the package card</p>
        </v-flex>
      </v-layout>

      <v-layout row v-if="item.attributes.styleData.displayType === 'icon-only'">
        <v-flex mr-5 class="half">
          <label class="label" for="style-mode">Icon</label>
          <v-select
            id="style-mode"
            v-model="item.attributes.styleData.icon"
            item-text='name'
            item-value='value'
            :items="[
              { name: 'None', value: '' },
              { name: 'Ball', value: 'Ball' },
              { name: 'Camera', value: 'Camera' },
              { name: 'Audio', value: 'Audio' },
              { name: 'Match', value: 'Match' },
            ]"
            solo
            flat
            required
            @change="updateData()"/>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>Icons are used on specific display types</p>
        </v-flex>
      </v-layout>

      <v-layout row v-if="item.attributes.styleData.displayType === 'feature-image'">
        <v-flex>
          <ImageUploader :title="'Landscape Image'" :widthOptionEnabled="false" :mediaLibraryID="item.attributes.imageData.landscape.imageID" @update="updateLandscapeImage($event)" :desc="'<p>Recommended size: 16:9</p><p>Only used on certain display types. This image will not crop</p>'"/>
        </v-flex>
      </v-layout>

      <v-layout row v-if="item.attributes.styleData.displayType === 'background-image'">
        <v-flex>
          <ImageUploader :title="'Background Image'" :widthOptionEnabled="false" :mediaLibraryID="item.attributes.styleData.backgroundImageID" @update="updateBackgroundImage($event)" :desc="'<p>Recommended size: 2:3</p><p>Only used on certain display types. This image can crop so please use more decorative images</p>'"/>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="custom-label">Custom label</label>
          <v-text-field
            id="custom-label"
            v-model="item.attributes.styleData.customLabel"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>Custom labels can be added to highlight certain packages (e.g. Most popular)</p>
        </v-flex>
      </v-layout>
    </section>

    <h3 class="subheading">Prices</h3>
    <section class="section-container">

      <template v-if="creatingPackage">

        <div class="price-row">
          <p class="warning-text">All items with an asterisk are required</p>
          <v-layout row>
            <v-flex mr-5 class="half">
              <label class="label" for="price-name">Price name<sup>*</sup></label>
              <v-text-field
                id="price-name"
                v-model="item.attributes.priceData[0].name"
                solo
                flat
                @change="updateData()"
                :error="basicValidateCheck(item.attributes.priceData[0].name)"
              ></v-text-field>
            </v-flex>
            <v-flex class="half">
              <label class="label" for="active">Active</label>
              <v-select
                id="active"
                v-model="item.attributes.priceData[0].active"
                item-text='name'
                item-value='value'
                :items="[
                  { name: 'Active', value: true },
                  { name: 'Disabled', value: false }
                ]"
                solo
                flat
                required
                @change="updateData()"/>
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex mr-5 class="half">

              <entitlements-picker
                class="entitlements-picker"
                :activeEntitlements="convertEntitlements()"
                @updated="updatedEntitlements($event)" />

            </v-flex>
            <v-flex class="half">

            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex mr-5 class="half">
              <label class="label" for="currency">Currency</label>
              <v-select
                id="currency"
                v-model="item.attributes.priceData[0].currency"
                item-text='name'
                item-value='value'
                :items="supportedCurrencies"
                solo
                flat
                required
                @change="updateData()"/>
            </v-flex>
            <v-flex class="half">
              <label v-if="item.attributes.priceData[0].currency === 'GBP'" class="label" for="amount">Amount as pence<sup>*</sup></label>
              <label v-else-if="item.attributes.priceData[0].currency === 'USD'" class="label" for="amount">Amount as cents<sup>*</sup></label>
              <label v-else class="label" for="amount">Amount as minor units (e.g. pence, cents)<sup>*</sup></label>
              <v-text-field
                id="amount"
                type="number"
                v-model.number="item.attributes.priceData[0].amount"
                solo
                flat
                @change="updateData()"
                :error="basicValidateCheck(item.attributes.priceData[0].amount)"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex mr-5 class="half">
              <label class="label" for="tax-behaviour">Tax Behaviour</label>
              <v-select
                id="tax-behaviour"
                v-model="item.attributes.priceData[0].taxBehavior"
                item-text='name'
                item-value='value'
                :items="[
                  { name: 'Inclusive', value: 'inclusive' },
                  { name: 'Exclusive', value: 'exclusive' }
                ]"
                solo
                flat
                required
                @change="updateData()"/>
            </v-flex>
            <v-flex class="half">
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex mr-5 class="half">
              <label class="label" for="price-display">Button Price Display</label>
              <v-text-field
                id="price-display"
                v-model="item.attributes.priceData[0].priceDisplay"
                solo
                flat
                @change="updateData()"
              ></v-text-field>
            </v-flex>
            <v-flex class="half">

            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex mr-5 class="half">
              <label class="label" for="media-type">Package Type</label>
              <v-select
                id="media-type"
                v-model="item.attributes.priceData[0].type"
                item-text='name'
                item-value='value'
                :items="[
                  { name: 'One Time', value: 'one-time' },
                  { name: 'Recurring', value: 'recurring' }
                ]"
                solo
                flat
                required
                @change="updateData()"/>
            </v-flex>
            <v-flex class="half">

            </v-flex>
          </v-layout>

          <v-layout row v-if="item.attributes.priceData[0].type === 'recurring'">
            <v-flex mr-5 class="half">
              <label class="label" for="currency">Interval</label>
              <v-select
                id="currency"
                v-model="item.attributes.priceData[0].recurring.interval"
                item-text='name'
                item-value='value'
                :items="[
                  { name: 'Month', value: 'month' },
                  { name: 'Year', value: 'year' },
                  { name: 'Week', value: 'week' },
                  { name: 'Day', value: 'day' }
                ]"
                solo
                flat
                required
                @change="updateData()"/>
            </v-flex>
            <v-flex class="half">
              <label class="label" for="interval-count">Interval Count</label>
              <v-text-field
                id="interval-count"
                type="number"
                v-model.number="item.attributes.priceData[0].recurring.intervalCount"
                solo
                flat
                @change="updateData()"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex mr-5 mb-4 class="half">
              <label class="label" for="enable-country-restrictions">Enable country restrictions</label>

                <v-select
                  id="enable-country-restrictions"
                  v-model="enablePriceCountryRestrictions"
                  readonly
                  item-text='name'
                  item-value='value'
                  :items="[
                    { name: 'Yes', value: true },
                    { name: 'No', value: false }
                  ]"
                  solo
                  flat
                  required
                  @change="clearPriceCountryRestrictions()"/>
            </v-flex>
            <v-flex mt-4 class="half">
              <p>Country restrictions must be used for prices. This is where you define which countries will see this price.</p>
            </v-flex>
          </v-layout>

          <template v-if="enablePriceCountryRestrictions">
            <v-layout row>
              <v-flex mr-5 class="half">
                <label class="label" for="georestriction-profile">Georestriction Prepopulate</label>
                <v-select
                  v-if="georestrictionProfilesLoaded"
                  id="georestriction-profile"
                  v-model="item.attributes.priceData[0].accessData.countryProfile"
                  :items="georestrictionProfiles"
                  item-text="profileName"
                  item-value="id"
                  return-object
                  solo
                  flat
                  @change="selectPriceGeorestrictionProfile($event); updateData()">
                </v-select>
              </v-flex>
              <v-flex mt-4 class="half">
                <p>Use a predefined profile to quickly add a set of countries for Georestriction. Any countries not in this list will be prevented access.</p>
              </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex>
                <label class="label" for="georestriction-countries-profile">Countries allowed</label>

                <v-autocomplete
                  v-if="allowedCountriesProfileLoaded"
                  id="georestriction-countries-profile"
                  v-model="item.attributes.priceData[0].accessData.allowedCountriesProfile"
                  :items="countriesList"
                  item-value="alpha2Code"
                  item-text="enShortName"
                  no-data-text="loading..."
                  multiple
                  solo
                  flat
                  dense
                  chips
                  @change="updateData()"
                  >
                </v-autocomplete>
              </v-flex>
            </v-layout>
          </template>

          <v-layou v-if="!modules.default.LFCExclusive" row>
            <v-flex mr-5 class="half">
              <label class="label" for="further-info">Further info</label>
              <v-textarea
                solo
                flat
                rows="4"
                id="further-info"
                v-model="item.attributes.priceData[0].furtherInformation"
                @change="updateData()"
              ></v-textarea>
            </v-flex>
            <v-flex class="half">

            </v-flex>
          </v-layou>
        </div>

        <p>Additional prices can only be added once your package is saved</p>
      </template>

      <template v-else>
        <table class="price-table">
          <thead>
            <th>Name</th>
            <th>Active</th>
            <th>Currency</th>
            <th>Amount</th>
            <th>Interval</th>
            <th></th>
          </thead>
          <tbody>
            <tr v-for="price in item.attributes.priceData" :key="price.id">
              <td>{{ price.name }}</td>
              <td>{{ price.active }}</td>
              <td>{{ price.currency }}</td>
              <td>{{ price.amount }}</td>
              <td>
                <template v-if="price.type === 'recurring' && price.recurring">{{ price.recurring.intervalCount }} / {{ price.recurring.interval }}
                </template>
                <template v-else>n/a</template>
                </td>
              <td><v-btn @click="editPrice(price.id)">Edit Details</v-btn></td>
            </tr>
          </tbody>
        </table>

        <div class="add-more">
          <v-btn @click="addNewPrice()">Add New Price</v-btn>
        </div>
        <p class="warning-text">Once created, prices cannot be deleted and the amount cannot be modified because this data is also created in Stripe. You may, however, disable a price</p>
      </template>
    </section>

    <template v-if="modules.default.LFCExclusive && item.attributes.promotionData">
      <h3 class="subheading">Promotions</h3>

      <section class="section-container">
        <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="custom-label">Show promotion details</label>
          <v-select
              id="stripeAllowPromotionCodes"
              v-model="item.attributes.promotionData.enabled"
              item-text='name'
              item-value='value'
              :items="[
                { name: 'Enable Promotion', value: 1 },
                { name: 'Disable Promotion', value: 0 },
              ]"
              solo
              flat
              required
              @change="updateData()"/>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>This field controls whether the promotion data will appear on the website</p>
        </v-flex>
      </v-layout>

        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="custom-label">Allow code to be entered manually at checkout</label>
            <v-select
                id="stripeAllowPromotionCodes"
                v-model="item.attributes.promotionData.stripeAllowPromotionCodes"
                item-text='name'
                item-value='value'
                :items="[
                  { name: 'Apply code automatically', value: 'always' },
                  { name: 'If the promotion is enabled, the user must enter the code manually', value: 'never' },
                  { name: 'If the promotions are disabled, the user can still type the code manually', value: 'allow_if_no_discount' },
                ]"
                solo
                flat
                required
                @change="updateData()"/>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>If the promotion is disabled, this field allows users to type promotion codes at checkout</p>
          </v-flex>
        </v-layout>

        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="custom-label">Stripe Promotion ID</label>
            <v-text-field
              id="stripePromotionID"
              v-model="item.attributes.promotionData.stripePromotionID"
              solo
              flat
              @change="updateData()"
            ></v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>
              The ID you have set in Stripe for this promotion. <i>Saving the package data will fail if this is wrong</i>
            </p>
          </v-flex>
        </v-layout>

        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="custom-label">Promotion text</label>
            <v-text-field
              id="offerText"
              v-model="item.attributes.promotionData.offerText"
              solo
              flat
              placeholder="Best offer"
              @change="updateData()"
            ></v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>If defined, this text appears as a golden label where the packages are shown. This has to be a small piece of text</p>
          </v-flex>
        </v-layout>

        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="custom-label">Disclaimer text</label>
            <v-text-field
              id="disclaimerText"
              v-model="item.attributes.promotionData.disclaimerText"
              solo
              flat
              placeholder="Discount applied for 12 months"
              @change="updateData()"
            ></v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>If defined, displays the terms of the discount in the Stripe checkout</p>
          </v-flex>
        </v-layout>
        
        <v-layout v-if="item.attributes.promotionData.stripePromotionCode || item.attributes.promotionData.stripePromotionPercentageDiscount" row>
          <v-flex mr-5 class="half">
            <label v-if="item.attributes.promotionData.stripePromotionCode" class="label" for="custom-label">Promotion Code</label>
            <v-text-field
              v-if="item.attributes.promotionData.stripePromotionCode"
              id="disclaimerText"
              v-model="item.attributes.promotionData.stripePromotionCode"
              solo
              flat
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <label v-if="item.attributes.promotionData.stripePromotionPercentageDiscount" class="label" for="custom-label">Promotion Percentage Discount: {{ item.attributes.promotionData.stripePromotionPercentageDiscount + '%' }}</label>
            <p>Values retrieved from Stripe. Cannot be edited here and updates when this page is reloaded</p>
          </v-flex>
        </v-layout>
      </section>

    </template>

    <template v-if="item.attributes.vendorData">
      <h3 class="subheading">Vendor info</h3>
      <section class="section-container">
        <v-layout row>
          <v-flex mr-5 class="half">
          <table class="price-table">
            <thead>
              <th>Vendor</th>
              <th>ID</th>
            </thead>
            <tbody>
              <tr v-for="vendor in item.attributes.vendorData" :key="vendor.id">
                <td>{{ vendor.vendor }}</td>
                <td>{{ vendor.id }}</td>
              </tr>
            </tbody>
          </table>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>Vendor information is automatically populated when a relevant vendor is connected to this product</p>
          </v-flex>
        </v-layout>
      </section>
    </template>

    <h3 class="subheading">Restrictions</h3>
    <section class="section-container">

      <v-layout row>
        <v-flex mr-5 mb-4 class="half">
          <label class="label" for="enable-country-restrictions">Enable country restrictions</label>

            <v-select
              id="enable-country-restrictions"
              v-model="enableCountryRestrictions"
              item-text='name'
              item-value='value'
              :items="[
                { name: 'Yes', value: true },
                { name: 'No', value: false }
              ]"
              solo
              flat
              required
              @change="clearCountryRestrictions()"/>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>If country restrictions are disabled then users from all countries will be able to access this resource</p>
        </v-flex>
      </v-layout>

      <template v-if="enableCountryRestrictions">
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="georestriction-profile">Georestriction Prepopulate</label>
            <v-select
              v-if="georestrictionProfilesLoaded"
              id="georestriction-profile"
              v-model="item.attributes.accessData.countryProfile"
              :items="georestrictionProfiles"
              item-text="profileName"
              item-value="id"
              return-object
              solo
              flat
              @change="selectGeorestrictionProfile($event); updateData()">
            </v-select>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>Use a predefined profile to quickly add a set of countries for Georestriction. Any countries not in this list will be prevented access.</p>
          </v-flex>
        </v-layout>

        <div v-if="item.attributes.accessData.allowedCountriesSpecific.length">
          <p>These countries should be removed from the data, please notify Urban Zoo with this asset ID</p>
          <pre>{{ item.attributes.accessData.allowedCountriesSpecific }}</pre>
        </div>

        <v-layout row>
          <v-flex>
            <label class="label" for="georestriction-countries-profile">Countries allowed</label>

            <v-autocomplete
              v-if="allowedCountriesProfileLoaded"
              id="georestriction-countries-profile"
              outline
              v-model="item.attributes.accessData.allowedCountriesProfile"
              :items="countriesList"
              item-value="alpha2Code"
              item-text="enShortName"
              no-data-text="loading..."
              multiple
              solo
              flat
              dense
              chips
              @change="updateData()"
              >
            </v-autocomplete>
          </v-flex>
        </v-layout>

        <!-- <v-layout row>
          // Removed due to allowing editing on main list
          <v-flex>
            <label class="label" for="georestriction-countries-specific">Manually allowed countries</label>

            <v-autocomplete
              id="georestriction-countries-specific"
              outline
              v-model="item.attributes.accessData.allowedCountriesSpecific"
              :items="countriesList"
              item-value="alpha2Code"
              item-text="enShortName"
              no-data-text="loading..."
              multiple
              solo
              flat
              clearable
              dense
              chips
              @change="updateData()"
              >
            </v-autocomplete>
          </v-flex>
        </v-layout> -->
      </template>
    </section>

    <h3 class="subheading">Publishing</h3>
    <section class="section-container">
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="publish">Published date</label>

          <datetime
            v-model="item.attributes.publicationData.startAt"
            type="datetime"
            @change="updateData()"></datetime>

          <p class="utc-note">This will publish at {{ item.attributes.publicationData.startAt }}</p>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>Please enter the published date and time as your local time if you want the post to automatically publish</p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="expiry">Expiry date</label>

          <datetime
            v-model="item.attributes.publicationData.finishAt"
            type="datetime"
            @change="updateData()"></datetime>

          <p class="utc-note">This will expire at {{ item.attributes.publicationData.finishAt }}</p>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>Please enter the expiry date and time as your local time if you want the post to automatically remove</p>
        </v-flex>
      </v-layout>

      <v-layout row class="published-wrap">
        <v-flex mr-4 class="half">
          <div class="published-row">
            <label class="label published-row__label" for="published">Published</label>
            <v-select
            id="published"
            v-model="item.attributes.publicationData.published"
            item-text='name'
            item-value='value'
            :items="[
              { name: 'Draft', value: 0 },
              { name: 'Published', value: 1 }
            ]"
            solo
            flat
            required
            @change="updateData()"/>
          </div>
        </v-flex>

        <v-flex mt-4 class="half">
          <p><i>Required</i> - Packages will only display if they are published</p>
        </v-flex>
      </v-layout>

    </section>

    <v-layout row v-if="!creatingPackage">
      <v-flex mt-3 mr-5 class="half">
        <v-btn @click="deletePackage()" class="" color="error" type="button" depressed>
          <v-icon class="mr-2 mb-1" small>delete_forever</v-icon>
          Delete Package
        </v-btn>
      </v-flex>

      <v-flex mt-4 class="half">
        <p><i>Warning: This will permanently remove this package</i></p>
      </v-flex>
    </v-layout>

    <packages-price-editor
      v-if="openPriceEditor"
      @closeDialog="priceEditorClosed()"
      :editingPriceID="editingPriceID"
      :packageID="$route.params.id" />

    <StickyFooter :errorMessage="errorMessage" :status="status" @buttonClicked="saveItem()" />
  </section>
        
</template>

<script>
import axios from "axios"
import EntitlementsPicker from "@/components/Library/EntitlementsPicker"
import ImageUploader from "@/components/Library/ImageUploader"
import InlineLoader from "@/components/Library/InlineLoader"
import PackagesPriceEditor from "@/components/Streamline/PackagesPriceEditor"
import StickyFooter from "@/components/Library/StickyFooter"

export default {
  name: "PackagesContent",

  components: {
    EntitlementsPicker,
    ImageUploader,
    InlineLoader,
    PackagesPriceEditor,
    StickyFooter
  },

  data:() => ({
    modules: null,
    status: "saved",
    errorMessage: "",
    dataLoaded: false,
    openPriceEditor: false,
    editingPriceID: null,
    creatingPackage: null,
    supportedCurrencies: null,
    item: {
      attributes: {
        title: "",
        description: "",
        packageType: "one-time",
        statementDescriptor: "",
        successfulPaymentPage: "",
        cancelledPaymentPage: "",
        matchID: "",
        order: 1,
        imageData: {
          landscape: {
            imageID: "",
            imageKey: ""
          }
        },
        styleData: {
          backgroundImageID: "",
          backgroundImageKey: "",
          styleMode: "lightMode"
        },
        priceData: [
          {
            name: "Default",
            active: true,
            accessData: {
              entitlements: "",
              countryProfile: {},
              allowedCountriesProfile: []
            },
            currency: "GBP",
            amount: "",
            taxBehavior: "inclusive",
            priceDisplay: "",
            type: "one-time",
            recurring: {
              intervalCount: 1,
              interval: "month"
            },
            furtherInformation: ""
          }
        ],
        accessData: {
          allowedCountriesSpecific: [],
          countryProfile: {},
          allowedCountriesProfile: []
        },
        publicationData: {
          published: 0
        },
        promotionData: {
          enabled: 0,
          stripePromotionID: "",
          stripeAllowPromotionCodes: "always",
          offerText: "",
          disclaimerText: "",
        }
      }
    },
    enableCountryRestrictions: false,
    enablePriceCountryRestrictions: true,
    countriesList: [],
    countriesLoaded: false,
    georestrictionProfiles: [],
    georestrictionProfilesLoaded: false,
    allowedCountriesProfileLoaded: true,
    validating: false,
  }),

  watch: {
    "$route": function(to, from){
      // console.log('watcher', this.$route)
       if (to.path != "/streamline/package/create-package") {
        this.creatingPackage = false
        this.fetchItem()
      }
    }
  },

  created() {
    this.modules = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/modules.js")
    const defaultSettings = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/defaultSettings.js")
    
    if (defaultSettings.default.supportedCurrencies && defaultSettings.default.supportedCurrencies.length) {
      this.supportedCurrencies = defaultSettings.default.supportedCurrencies
    } else {
      this.supportedCurrencies = [{ name: "GBP", value: "GBP" }, { name: "USD", value: "USD" }]
    }
  },

  mounted() {
    console.log("Mounted packages content")
    if (this.$route.path === "/streamline/package/create-package") {
      this.creatingPackage = true
      this.dataLoaded = true
      this.status = "needsSaving"
    } else {
      this.creatingPackage = false
      this.fetchItem()
    }

    this.getCountries()
    this.fetchGeorestrictionProfiles()
  },

  methods: {
    updateData() {
      this.status = "needsSaving";
      this.errorMessage = ""
      console.log(this.item);
    },

    selectGeorestrictionProfile($event) {
      console.log("selectGeorestrictionProfile", $event)

      this.allowedCountriesProfileLoaded = false

      this.item.attributes.accessData.allowedCountriesProfile = $event.countries

      this.allowedCountriesProfileLoaded = true
    },

    selectPriceGeorestrictionProfile($event) {
      console.log("selectPriceGeorestrictionProfile", $event)

      this.allowedCountriesProfileLoaded = false

      this.item.attributes.priceData[0].accessData.allowedCountriesProfile = $event.countries

      this.allowedCountriesProfileLoaded = true
    },

    fetchGeorestrictionProfiles() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_STREAMLINEAPI}/countryProfiles`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log("Fetch profiles:", response);
          const profiles = response.data.data
          this.georestrictionProfiles = profiles.map(item => {
            return {
              id: item.id,
              profileName: item.attributes.profileName,
              countries: item.attributes.countries
            }
          })

          this.georestrictionProfilesLoaded = true
        })
        .catch(e => {
          console.error("Problem fetching profiles", e);
          this.georestrictionProfilesLoaded = true
        });
      })
    },

    getCountries() {
      this.countriesLoaded = false
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_COUNTRIESAPI}/countries`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          this.countriesList = response.data
          this.countriesLoaded = true
        })
        .catch(e => {
          console.error("Problem receiving countries", e)
        })
      })
    },

    updateLandscapeImage(payload) {
      this.item.attributes.imageData.landscape.imageID = payload.mediaLibraryID ? payload.mediaLibraryID : ""
      this.item.attributes.imageData.landscape.imageKey = payload.imageKey ? payload.imageKey : ""

      this.updateData()
    },

    updateBackgroundImage(payload) {
      this.item.attributes.styleData.backgroundImageID = payload.mediaLibraryID ? payload.mediaLibraryID : ""
      this.item.attributes.styleData.backgroundImageKey = payload.imageKey ? payload.imageKey : ""

      this.updateData()
    },

    fetchItem() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_STREAMLINEAPI}/packages/${this.$route.params.id}`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log("Fetch package:", response);
          this.item = response.data.data

          if (this.item.attributes.accessData.allowedCountriesProfile.length || this.item.attributes.accessData.allowedCountriesSpecific.length) {
            this.enableCountryRestrictions = true
          }

          if (!this.item.attributes.promotionData) {
            this.item.attributes.promotionData =  {
              enabled: 0,
              stripePromotionID: "",
              stripeAllowPromotionCodes: "always",
              offerText: "",
              disclaimerText: "",
            }
          }

          this.dataLoaded = true
          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem fetching package", e);
          this.$store.commit("completeLoading");
        });
      })
    },

    createItem() {
      this.$store.commit("startLoading");

      const priceData = [{
        name: this.item.attributes.priceData[0].name,
        active: this.item.attributes.priceData[0].active,
        accessData: {
          entitlements: this.item.attributes.priceData[0].accessData.entitlements,
          countryProfile: this.item.attributes.priceData[0].accessData.countryProfile,
          allowedCountriesProfile: this.item.attributes.priceData[0].accessData.allowedCountriesProfile,
        },
        currency: this.item.attributes.priceData[0].currency,
        amount: this.item.attributes.priceData[0].amount,
        taxBehavior: this.item.attributes.priceData[0].taxBehavior,
        priceDisplay: this.item.attributes.priceData[0].priceDisplay,
        type: this.item.attributes.priceData[0].type,
        furtherInformation: this.item.attributes.priceData[0].furtherInformation
      }]

      if (this.item.attributes.priceData[0].type === "recurring") {
        priceData[0].recurring = this.item.attributes.priceData[0].recurring
      }

      const publicationData = {
        published: this.item.attributes.publicationData.published
      }

      if (this.item.attributes.publicationData.startAt) {
        publicationData.startAt = this.item.attributes.publicationData.startAt
      }

      if (this.item.attributes.publicationData.finishAt) {
        publicationData.finishAt = this.item.attributes.publicationData.finishAt
      }

      const {stripePromotionCode,  stripePromotionPercentageDiscount, ...promotionData} = this.item.attributes.promotionData

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${process.env.VUE_APP_STREAMLINEAPI}/packages`,
          headers: { "Authorization": jwt },
          data: {
            data: {
              type: "package",
              attributes: {
                title: this.item.attributes.title,
                description: this.item.attributes.description,
                statementDescriptor: this.item.attributes.statementDescriptor,
                successfulPaymentPage: this.item.attributes.successfulPaymentPage,
                cancelledPaymentPage: this.item.attributes.cancelledPaymentPage,
                matchID: this.item.attributes.matchID,
                packageType: this.item.attributes.packageType,
                order: parseInt(this.item.attributes.order),
                imageData: this.item.attributes.imageData,
                styleData: this.item.attributes.styleData,
                priceData: priceData,
                accessData: this.item.attributes.accessData,
                publicationData: publicationData,
                promotionData: promotionData
              }
            }
          }
        })
        .then(response => {
          console.log("Package Created: ", response);

          this.$store.commit("completeLoading");
          this.status = "saved";
          this.errorMessage = "";

          this.$router.push({ path: `/streamline/package/${response.data.data.id}` })
        })
        .catch(e => {
          console.error("Problem creating package: ", e);            
          this.$store.commit("completeLoading");
            if (e.response.data.errors[0].detail.includes("No such promotion code") && e.response.data.errors[0].code == "resource_missing") {
              this.errorMessage = "Stripe Promotion ID not found"
            } else if (e.response.data.errors[0].detail.includes("Promotion Code is not applicable to this Package")) {
                this.errorMessage = "The provided Promotion Code is not applicable to this Package"
            } else {
              this.errorMessage = "Problem saving package"
            }
        });
      })
    },

    saveItem() {
      if (this.item.attributes.title && this.item.attributes.priceData[0].name && this.item.attributes.priceData[0].amount && this.item.attributes.priceData[0].accessData.entitlements) {
        if (this.creatingPackage) {
          this.createItem()
        } else {
          this.$store.commit("startLoading");

          const publicationData = {
            published: this.item.attributes.publicationData.published
          }

          if (this.item.attributes.publicationData.startAt) {
            publicationData.startAt = this.item.attributes.publicationData.startAt
          }

          if (this.item.attributes.publicationData.finishAt) {
            publicationData.finishAt = this.item.attributes.publicationData.finishAt
          }

          // Fix for allowedCountriesSpecific which apparently should not exist anymore
          const { allowedCountriesSpecific, ...accessData } = this.item.attributes.accessData

          const {stripePromotionCode,  stripePromotionPercentageDiscount, ...promotionData} = this.item.attributes.promotionData

          this.$getCurrentUserJwToken().subscribe((jwt) => {
            axios({
              method: "PATCH",
              url: `${process.env.VUE_APP_STREAMLINEAPI}/packages/${this.$route.params.id}`,
              headers: { "Authorization": jwt },
              data: {
                data: {
                  type: "package",
                  id: this.$route.params.id,
                  attributes: {
                    title: this.item.attributes.title,
                    description: this.item.attributes.description,
                    statementDescriptor: this.item.attributes.statementDescriptor,
                    successfulPaymentPage: this.item.attributes.successfulPaymentPage,
                    cancelledPaymentPage: this.item.attributes.cancelledPaymentPage,
                    matchID: this.item.attributes.matchID ? this.item.attributes.matchID : "",
                    packageType: this.item.attributes.packageType,
                    order: parseInt(this.item.attributes.order),
                    imageData: this.item.attributes.imageData,
                    styleData: this.item.attributes.styleData,
                    accessData: accessData,
                    publicationData: publicationData,
                    promotionData:  promotionData
                  }
                }
              }
            })
            .then(response => {
              console.log("Package saved: ", response);

              this.$store.commit("completeLoading");
              this.status = "saved";
            })
            .catch(e => {
              console.error("Problem saving package: ", e);
              this.$store.commit("completeLoading");
              if (e.response.data.errors[0].detail.includes("No such promotion code") && e.response.data.errors[0].code == "resource_missing") {
                this.errorMessage = "Stripe Promotion ID not found"
              } else if (e.response.data.errors[0].detail.includes("Promotion Code is not applicable to this Package")) {
                this.errorMessage = "The provided Promotion Code is not applicable to this Package"
              } else {
                this.errorMessage = "Problem saving package"
              }
            });
          })
        }
      } else {
        alert("Problem validating package. You must enter a title, price name, price entitlement and price amount")
        this.validating = true
        this.errorMessage = "Problem validating package"
      }
    },

    deletePackage() {
      if (confirm("This will permanently delete the package. \nAre you sure you want to continue?")) {
        this.$store.commit("startLoading");

        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "DELETE",
            url: `${process.env.VUE_APP_STREAMLINEAPI}/packages/${this.$route.params.id}`,
            headers: { "Authorization": jwt }
          })
          .then(response => {
            console.log("Delete package:", response);
            this.$store.commit("completeLoading");

            this.$router.push("/streamline")
          })
          .catch(e => {
            console.error("Problem deleting package", e);

            alert("There was a problem deleting the package")
            this.$store.commit("completeLoading");
          });
        })
      }
    },

    priceEditorClosed() {
      this.openPriceEditor = false
      this.dataLoaded = false
      this.fetchItem()
    },

    editPrice(id) {
      this.editingPriceID = id
      this.openPriceEditor = true
    },

    addNewPrice() {
      this.editingPriceID = null
      this.openPriceEditor = true
    },

    basicValidateCheck(value) {
      if (this.validating && !value) {
        return true
      }
    },

    clearCountryRestrictions() {
      if (!this.enableCountryRestrictions) {
        this.item.attributes.accessData.countryProfile = {}
        this.item.attributes.accessData.allowedCountriesProfile = []
        this.item.attributes.accessData.allowedCountriesSpecific = []

        this.updateData()
      }
    },

    clearPriceCountryRestrictions() {
      if (!this.enablePriceCountryRestrictions) {
        this.item.attributes.priceData[0].accessData.countryProfile = {}
        this.item.attributes.priceData[0].accessData.allowedCountriesProfile = []

        this.updateData()
      }
    },

    convertEntitlements() {
      const entitlements = this.item.attributes.priceData[0].accessData.entitlements
      return entitlements.split(",")
    },

    updatedEntitlements(payload) {
      console.log("updatedEntitlements", payload)
      
      const filteredEntitlements = payload.filter(element => element !== undefined);

      this.item.attributes.priceData[0].accessData.entitlements = filteredEntitlements.toString()
      this.updateData()
    }
  }
}
</script>

<style scoped lang="scss">
  .half {
    width: 50%;
  }

  .third-x2 {
    width: 66.66%;
  }

  .third {
    width: 33.33%;
  }

  .price-row {
    padding: 16px 16px 0;
    width: 100%;
    background: #E6E8F1;
    border: 1px solid #DFE3E9;
    border-radius: 4px;
  }

  .price-table {
    width: 100%;
    border: 1px solid #DFE3E9;
    border-radius: 4px;

    th {
      padding: 4px 8px;
      font-weight: 500;
      text-align: left;
      background: #E6E8F1;
      border-bottom: 1px solid #DFE3E9;
    }

    td {
      padding: 4px 8px;
      border-bottom: 1px solid #DFE3E9;
    }

    tr:last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  .package-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 140px;
    margin: 16px 0 48px;
    padding: 32px;
    background-color: #fff;
    border: 1px solid #dfe3e9;
    border-radius: 4px;

    &__image {
      max-width: 160px;
    }

    &__inner {
      padding: 0 0 0 32px;
      width: 100%;
    }

    &__id {
      margin: 0;
      font-size: 11px;
      opacity: 0.5;
      word-break: break-all;
    }

    &__subheading {
      margin: 0;
      padding: 5px 0 0;
    }

    &__label {
      margin: 4px 0;
      padding: 0;
      font-size: 12px;
      word-break: break-all;
    }

    &__statuses {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  .status {
    display: inline-block;
    padding: 3px 12px;
    margin: 0 8px 8px 0;
    font-size: 10px;
    color: #fff;
    background-color: #ccc;
    border-radius: 4px;

    &.status--draft {
      background-color: color(draftHighlight);
    }

    &.status--published {
      background-color: #67247c;
    }
  }

  .entitlement-pill {
    padding: 4px 4px;
    margin: 0 4px 0 0;
    font-size: 13px;
    background-color: #E6E8F1;
    border: 1px solid #DFE3E9;
    border-radius: 8px;
  }

  .entitlement-price {
    font-weight: 500;
  }

  .warning-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f6d2;
    padding: 8px 16px;
    border: 1px solid #d6d8b0;
    border-radius: 8px;
    margin-top: 16px;
    margin-bottom: 0;
  }

.entitlements-picker {
  /deep/ label::after {
    content: '*'
  }
}

.warning-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f6d2;
  padding: 8px 16px;
  border: 1px solid #d6d8b0;
  border-radius: 8px;
  margin-bottom: 16px;
}
</style>
